import React, { useState, useRef, useEffect } from "react"
import CourseCard from "../components/courseCard"

import leftActiveIcon from "../images/scroll-left-active.svg"
import leftInactiveIcon from "../images/scroll-left-inactive.svg"
import rightActiveIcon from "../images/scroll-right-active.svg"
import rightInactiveIcon from "../images/scroll-right-inactive.svg"

const CourseCardGroup = (props) => {
  const subjectList = props.subjects

  const subjectELs = useRef([])
  const [subjectIndex, setSubjectIndex] = useState(0)
  const [rightSubjectIconActive, setRightSubjectIconActive] = useState(true)

  useEffect(() => {
    if (subjectIndex + 1 < subjectList.length) {
      const scrollLeftValue = subjectELs.current[subjectIndex + 1].offsetLeft
      const lastSubjectRightValue = subjectELs.current[
        subjectList.length - 1
      ].getBoundingClientRect().right
      setRightSubjectIconActive(lastSubjectRightValue > scrollLeftValue)
      setEndReached(lastSubjectRightValue < scrollLeftValue)
    }
  }, [subjectIndex, subjectList.length])

  const leftArrowHandler = () => {
    if (subjectIndex >= 1) {
      const courseGroup = document.getElementById("courses")
      const scrollLeftValue = document.getElementById(
        `course-${subjectIndex - 1}`
      ).offsetLeft
      courseGroup.scrollLeft = scrollLeftValue
      setSubjectIndex(subjectIndex - 1)
      setRightSubjectIconActive(true)
      setPreviousCardPosition(0)
    }
  }

  const [previousCardPosition, setPreviousCardPosition] = useState(0)
  const [endReached, setEndReached] = useState(false)

  const cardWidth = 280 // px min change size
  useEffect(() => {
    let currentCardPosition = subjectELs.current[
      subjectList.length - 1
    ].getBoundingClientRect().right
    if (
      previousCardPosition - currentCardPosition < cardWidth - 1 &&
      previousCardPosition - currentCardPosition > 0
    ) {
      setRightSubjectIconActive(false)
      setEndReached(true)
    }
  })

  const rightArrowHandler = () => {
    if (subjectIndex + 1 < subjectList.length) {
      const courseGroup = document.getElementById("courses")
      const scrollLeftValue = document.getElementById(
        `course-${subjectIndex + 1}`
      ).offsetLeft
      const lastSubjectRightValue = subjectELs.current[
        subjectList.length - 1
      ].getBoundingClientRect().right
      setPreviousCardPosition(
        subjectELs.current[subjectList.length - 1].getBoundingClientRect().right
      )
      if (lastSubjectRightValue > scrollLeftValue) {
        courseGroup.scrollLeft = scrollLeftValue
        setSubjectIndex(subjectIndex + 1)
      }
    }
  }

  const [direction, setDirection] = useState(true) // true = right, false = left
  const [tickTock, setTickTock] = useState(true) // Allows functions to be called repeatedly when this changes.

  const autoScroll = false // enable/diable carousel scroll
  const time = 5000 //ms

  useEffect(() => {
    if (autoScroll) {
      if (subjectIndex === 0) {
        setDirection(true)
      } else if (endReached) {
        setDirection(false)
      }
    }
  })

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoScroll) {
        if (direction) {
          rightArrowHandler()
          tickTock ? setTickTock(false) : setTickTock(true)
        } else if (!direction) {
          leftArrowHandler()
          tickTock ? setTickTock(false) : setTickTock(true)
        }
      }
    }, time)

    return () => clearInterval(interval)
  }, [tickTock])

  const [urlEnd, setUrlEnd] = useState()
  useEffect(() => {
    if (window !== undefined) {
      const url = window.location.href
      setUrlEnd(url.slice(url.length - 8, url.length))
    }
  }, [])

  return (
    <>
      <div
        className="course-card-group"
        id="courses"
        style={{ flexWrap: urlEnd === "subjects" ? "wrap" : "" }}
      >
        {subjectList.map((_, idx) => (
          <CourseCard
            {..._}
            id={`course-${idx}`}
            key={idx}
            ref={(el) => subjectELs.current.push(el)}
          />
        ))}
      </div>
      <div
        className="course-arrow-group"
        style={{ display: urlEnd === "subjects" ? "none" : "" }}
      >
        <div
          role="button"
          tabIndex={0}
          onClick={(e) => leftArrowHandler()}
          onKeyDown={(e) => {
            console.log(e.keyCode)
            if (e.keyCode === 37) leftArrowHandler()
          }}
        >
          <img
            src={subjectIndex > 0 ? leftActiveIcon : leftInactiveIcon}
            style={{ marginRight: "20px" }}
            alt="left arrow"
          />
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={(e) => {
            if (rightSubjectIconActive) rightArrowHandler()
          }}
          onKeyDown={(e) => {
            console.log(e.keyCode)
            if (e.keyCode === 38) rightArrowHandler()
          }}
        >
          <img
            src={rightSubjectIconActive ? rightActiveIcon : rightInactiveIcon}
            alt="right arrow"
          />
        </div>
      </div>
    </>
  )
}

export default CourseCardGroup
