import React from "react"
import { Link } from "gatsby"

import Layout from "../layout/layout"
import CourseCardGroup from "../components/courseCardGroup"
import Button from "../components/button"

import SUBJECTS_DATA from "../../data/subjects.json"

const SubjectsPage = () => {
  const subjects = SUBJECTS_DATA.subjects

  return (
    <Layout
      title="Subjects we offer for NZQA Courses"
      description="Browse by subject for the NZQA courses we offer"
    >
      <div className="layout-container subjects-container">
        <h3>Find the right course for you</h3>
        <p className="p-lg subjects-p">
          We have amassed extensive experience in each of our subject areas and
          as well as teaching the subjects, and sitting on panels at NZQA to
          further define the unit standards, we have walked the talk in the real
          world.
        </p>
        <CourseCardGroup subjects={subjects}></CourseCardGroup>
        <div className="subjects-help-button-container">
          <Link to="/contact">
            <Button>
              <span className="hidden-not-mobile ">
                Need help choosing what to take? Contact us
              </span>
              <span className="hidden-mobile">Need help? Contact us</span>
            </Button>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default SubjectsPage
