import React from "react"
import { Link } from "gatsby"

import rightArrow from "../images/right-arrow.svg"

const CourseCard = React.forwardRef((props, ref) => {
  const { 
    cardImage, 
    label, 
    brief, 
    slug, 
    dotColor,
    ...restProps
  } = props

  return (
    <div
      className="course-card-container"
      {...restProps}
      ref={ref}
    >
      <div 
        className="course-card-background"
        style={{backgroundImage: `url("${cardImage}")`}}
      >
      </div>
      <div 
        className="course-card-color-dot"
        style={{backgroundColor: dotColor}}
      >
      </div>
      <div className="course-card-content">
        <h6 style={{marginBottom: "10px"}}>{label}</h6>
        <p style={{marginBottom: "25px"}}>{brief}</p>
        <Link 
          to={`/subject/${slug}`}
          className="course-card-link"
        >
          See courses <img src={rightArrow} style={{marginLeft: "10px"}} alt="right arrow"/>
        </Link>
      </div>
    </div>
  )
})

export default CourseCard